module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withWebp":true,"maxWidth":800,"wrapperStyle":"width: 100%, margin: 0 auto;","backgroundColor":"transparent","quality":80,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/opt/build/repo/src/utils/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#d99e8e","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Sacramento","Dosis"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132211546-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Heba's Blog","name":"Heba El-Shimy Porfolio & Blog","start_url":"./index.html?launcher=true","display":"standalone","theme_color":"#000000","background_color":"#000000","icons":[{"src":"/assets/favicon.ico","sizes":"16x16 24x24 32x32 64x64","type":"image/x-icon"},{"src":"/images/touch/favicon-16x16.ico","sizes":"16x16","type":"image/x-icon"},{"src":"/images/touch/favicon-24x24.ico","sizes":"24x24","type":"image/x-icon"},{"src":"/images/touch/favicon-32x32.ico","sizes":"32x32","type":"image/x-icon"},{"src":"/images/touch/favicon-64x64.ico","sizes":"64x64","type":"image/x-icon"},{"src":"/images/touch/chrome-touch-icon-1x.png","sizes":"48x48","type":"image/png"},{"src":"/images/touch/chrome-touch-icon-2x.png","sizes":"96x96","type":"image/png"},{"src":"/images/touch/chrome-touch-icon-3x.png","sizes":"144x144","type":"image/png"},{"src":"/images/touch/chrome-touch-icon-4x.png","sizes":"192x192","type":"image/png"},{"src":"/images/touch/ms-touch-icon-144x144-precomposed.png","sizes":"144x144","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
